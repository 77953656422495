import React from "react";
import angiatour from "../../assets/images/THAI DAI VIET.png";
import xfooter_blog_1 from "../../assets/images/xfooter_blog_1.jpg.pagespeed.ic.n5JT1wzbm-.webp";
import xfooter_blog_2 from "../../assets/images/xfooter_blog_2.jpg.pagespeed.ic.u_Bp7zMu38.webp";
import xfooter_blog_3 from "../../assets/images/xfooter_blog_3.jpg.pagespeed.ic.XDkX_CloU3.webp";
import placeholder from "../../assets/images/placeholder.svg";
import phone from "../../assets/images/phone-call.svg";
import message from "../../assets/images/message.svg";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer class="footer">
      <div class="footer__container">
        <div class="grid wide">
          <div class="row">
            <div class="col l-3 m-6 c-6 s-12">
              <div class="footer__container__about">
                <div class="container__about--logo">
                  <a>
                    <img src={angiatour} alt="" />
                  </a>
                </div>
                <div class="container__about--text">
                  <p>
                    Tìm kiếm cảm hứng? <br />
                    Hãy để chúng tôi giúp bạn tìm nơi hoàn hảo. Điểm đến lý
                    tưởng cho kỳ nghỉ cuối tuần hoặc tuần trăng mật
                  </p>
                </div>
                <Link to="/contact">
                  <div class="container__about--social">
                    <ul>
                      <li>
                        <a>
                          <FacebookOutlinedIcon className="social" />
                        </a>
                      </li>
                      <li>
                        <a>
                          <PhoneInTalkOutlinedIcon className="social" />
                        </a>
                      </li>
                      <li>
                        <a>
                          <InstagramIcon className="social" />
                        </a>
                      </li>
                      <li>
                        <a>
                          <YouTubeIcon className="social" />
                        </a>
                      </li>
                      <li>
                        <a>
                          <LinkedInIcon className="social" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Link>
              </div>
            </div>
            <div class="col l-3 m-6 c-6 s-12">
              <Link to="/service">
                <div class="footer__container__blog">
                  <h2>Bài Đăng</h2>
                  <div class="footer__container__blog--content">
                    <div class="container__blog__item">
                      <div class="container__blog__item--img">
                        <img src={xfooter_blog_1} alt="" />
                        <div class="container__blog__item--text">
                          <a>Travel with us this year</a>
                          <br />
                          <span>July 20, 2021</span>
                        </div>
                      </div>
                    </div>
                    <div class="container__blog__item">
                      <div class="container__blog__item--img">
                        <img src={xfooter_blog_2} alt="" />
                        <div class="container__blog__item--text">
                          <a>New destinations for you</a>
                          <br />
                          <span>July 20, 2021</span>
                        </div>
                      </div>
                    </div>
                    <div class="container__blog__item">
                      <div class="container__blog__item--img">
                        <img src={xfooter_blog_3} alt="" />
                        <div class="container__blog__item--text">
                          <a>Travel with us this year</a>
                          <br />
                          <span>July 20, 2021</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col l-3 m-6 c-6 s-12">
              <Link to="/service">
                <div class="footer__container__tags">
                  <h2>Tags</h2>
                  <div class="footer__container__tags__content">
                    <ul>
                      <li>
                        <a>du lịch</a>
                      </li>
                      <li>
                        <a>đặt phòng</a>
                      </li>
                      <li>
                        <a>đặt chuyến đi</a>
                      </li>
                      <li>
                        <a>đặt khách sạn</a>
                      </li>
                      <li>
                        <a>bữa tiệc</a>
                      </li>
                      <li>
                        <a>photography</a>
                      </li>
                      <li>
                        <a>adventure</a>
                      </li>
                      <li>
                        <a>travel</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col l-3 m-6 c-6 s-12">
              <div class="footer__container__contact">
                <h2>Liên HỆ</h2>
                <Link to="/contact">
                  <div class="footer__container__contact__content">
                    <div class="container__contact__info">
                      <div class="container__contact__info--img">
                        <img src={placeholder} alt="" />
                      </div>
                      <span>
                        Địa chỉ: Số 294 đường Bùi Hữu Nghĩa, KP 1, Phường Tân Vạn, Thành phố Biên Hoà, Tỉnh Đồng Nai
                      </span>
                    </div>
                    <div class="container__contact__info">
                      <div class="container__contact__info--img">
                        <img src={placeholder} alt="" />
                      </div>
                      <span>
                        Văn Phòng TPHCM: 43/8/2 đường Cầu Xéo, P.Tân Quý, Q. Tân
                        Phú, TP.HCM
                      </span>
                    </div>
                    <div class="container__contact__info">
                      <div class="container__contact__info--img">
                        <img src={phone} alt="" />
                      </div>
                      <span>
                        Tel:0947.154.565 
                      </span>
                    </div>
                    <div class="container__contact__info">
                      <div class="container__contact__info--img">
                        <img src={message} alt="" />
                      </div>
                      <span>truyen.thaidaiviet@gmail.com</span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <span class="copyright">
            Copyright © 2023 By
            <a> CÔNG TY TNHH DU LỊCH THÁI ĐẠI  VIỆT 1</a>
          </span>
        </div>
        <ul></ul>
      </div>
    </footer>
  );
}

import React, { useEffect, useState } from "react";
import "../../assets/css/grid.css";
import "../../assets/css/base.css";
import "../../assets/css/style.css";
import "../../assets/css/reponsive.css";
import { Link, useLocation } from "react-router-dom";
import angiatour from "../../assets/images/THAI DAI VIET.png";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import zalo from "../../assets/images/zalo-icon.png";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
const Navbar = () => {
  const openZaloAndSearch = () => {
    var phoneNumber = "0947154565 ";
    var zaloURL = "https://zalo.me/";
    var fullURL = zaloURL + phoneNumber;

    window.open(fullURL, "_blank");
  };

  const makePhoneCall = () => {
    var phoneNumber = "0947154565 ";
    window.location.href = "tel:" + phoneNumber;
  };
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const location = useLocation();
  const ParentMenuItem = styled.div`
    position: relative;
  `;

  const ChildMenu = styled.div`
    position: absolute;
    top: 5vh;
    left: -1px;
    width: 200px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: ${({ open }) => (open ? "block" : "none")};
  `;

  const ChildMenuItem = styled.div`
  padding: 12px 20px;
  color:#000000;
  font-weight: 600;
  font-size: 15px;
  background-color: #ffffff;
  line-height: 21px;
  text-align: left;
  transition: background-color 0.3s ease;
  cursor: pointer;
  transition: transform 0.3s ease; 
  &:hover {
    background-color: #f5f5f5;
    color:#000000;
    transform: translateY(5px); 

  &:last-child {
    border-bottom: none;
  }
`;
  const [isChildMenuOpen, setIsChildMenuOpen] = useState(false);
  const categories = useSelector((state) => state.category.categories);
  return (
    <header className="header">
      <div className={`header__topbar ${isScrolled ? "hide" : ""}`}>
        <div className="grid wide">
          <div className="header__topbar__container">
            <div className="row">
              <div className="l-6 m-8 c-8">
                <div className="header__topbar__container--contacts">
                  <span>0947.154.565 </span>
                  <span>
                    <a>
                      <FacebookOutlinedIcon className="i" />
                    </a>
                    <a>
                      <PhoneInTalkOutlinedIcon className="i" />
                    </a>
                    <a>
                      <InstagramIcon className="i" />
                    </a>
                    <a>
                      <YouTubeIcon className="i" />
                    </a>
                    <a>
                      <LinkedInIcon className="i" />
                    </a>
                  </span>
                </div>
              </div>
              <div className="l-6 m-4 c-4">
                <ul className="header__topbar__container--register">
                  {/* <a>
                    <li>Login</li>
                  </a>
                  <a>
                    <li>|</li>
                  </a>
                  <a>
                    <li>Register</li>
                  </a> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className={`header__nav ${isScrolled ? "scroll" : ""}`}>
        <div className="header__nav__container">
          <div className="grid wide">
            <div className="row">
              <div className="l-3 m-6 c-8">
                <Link to="/">
                  <div className="header__nav__container--logo">
                    <img src={angiatour} alt="angiatour" />
                  </div>
                </Link>
              </div>
              <div className="l-7 hide-on-tablet_mobile">
                <ul className="header__nav__container--list">
                  <li>
                    <Link to="/">
                      <a className={location.pathname === "/" ? "active" : ""}>
                        Trang Chủ
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <a
                        className={
                          location.pathname === "/about" ? "active" : ""
                        }
                      >
                        Giới Thiệu
                      </a>
                    </Link>
                  </li>
                  <li>
                    <ParentMenuItem
                      onMouseEnter={() => setIsChildMenuOpen(true)}
                      onMouseLeave={() => setIsChildMenuOpen(false)}
                    >
                      <Link to="/tours">
                        <a
                          href="none"
                          className={
                            location.pathname.includes("/tours")
                              ? "active"
                              : "" || location.pathname.includes("/tour")
                              ? "active"
                              : ""
                          }
                        >
                          Tours
                        </a>
                      </Link>
                      <ChildMenu open={isChildMenuOpen}>
                        {categories.map((category) => (
                          <Link
                            to={`/tours/${category.cat}`}
                            key={category._id}
                            style={{ textDecoration: "none" }}
                          >
                            <ChildMenuItem>{category.title}</ChildMenuItem>
                          </Link>
                        ))}
                      </ChildMenu>
                    </ParentMenuItem>
                  </li>
                  <li>
                    <Link to="/news">
                      <a
                        className={
                          location.pathname === "/news" ? "active" : ""
                        }
                      >
                        Tin Tức
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <a
                        className={
                          location.pathname === "/contact" ? "active" : ""
                        }
                      >
                        Liên Hệ
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/jobs">
                      <a
                        className={
                          location.pathname === "/jobs" ? "active" : ""
                        }
                      >
                        Tuyển dụng
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="l-3 m-6 c-4">
                <ViewHeadlineIcon
                  id="menu_open"
                  onClick={handleOpen}
                  class="list-tablet-mobile"
                  fontSize="large"
                  style={{ color: "#ffffff" }}
                />
                {/* <div className="header__nav__container--search">
                  <i className="fas fa-search" id="search"></i>
                  <br />
                  <input type="text" placeholder="Search..." />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="header__menu">
        {isOpen && (
          <div>
            <div className="header__menu__overlay"></div>
            <div className="header__menu__body--content open">
              <ul>
                <li>
                  <Link to="/">
                    <a className={location.pathname === "/" ? "active" : ""}>
                      Trang Chủ
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to="/about">
                    <a
                      className={location.pathname === "/about" ? "active" : ""}
                    >
                      Giới Thiệu
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to="/tours">
                    <a
                      className={location.pathname === "/tours" ? "active" : ""}
                    >
                      Tours
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to="/news">
                    <a
                      className={location.pathname === "/news" ? "active" : ""}
                    >
                      Tin Tức
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <a
                      className={
                        location.pathname === "/contact" ? "active" : ""
                      }
                    >
                      Liên Hệ
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to="/jobs">
                    <a
                      className={
                        location.pathname === "/jobs" ? "active" : ""
                      }
                    >
                      Tuyển dụng
                    </a>
                  </Link>
                </li>
              </ul>
              <CloseIcon className="i" id="menu_close" onClick={handleClose} />
            </div>
          </div>
        )}
      </div>
      <div className="scroll-top">
        <i className="fas fa-arrow-up"></i>
        <KeyboardDoubleArrowUpIcon />
      </div>
      <div className="zalo-float-button">
        <a
          href="tel:0902666576"
          className="zalo-link"
          onClick={openZaloAndSearch}
        >
          <img src={zalo} alt="Zalo Icon" className="zalo-icon" />
        </a>
      </div>
      <div className="phone-float-button" onClick={makePhoneCall}>
        <button className="phone-button">
          <PhoneInTalkIcon style={{ fontSize: "40px", marginTop: "10px" }} />
        </button>
      </div>
    </header>
  );
};

export default Navbar;
